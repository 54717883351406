import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AdminComponent} from './views/admin/admin.component';
import {ModulteppichComponent} from './views/modulteppich/modulteppich.component';
import {RedirectGuard} from './guards/redirect.guard';
import {RoleGuard} from './guards/role.guard';
import {StudentComponent} from './student/student.component';
import {UnauthorizedErrorPageComponent} from './error-pages/unauthorized-error-page/unauthorized-error-page.component';
import {AppComponent} from "./app.component";
import {V2adminComponent} from "./v2/components/v2admin/v2admin.component";

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: AppComponent,
    canActivate: [RedirectGuard]
  },
  {
    path: 'student',
    component: StudentComponent,
    canActivate: [RoleGuard],
    data: {
      role: ['student']
    }
  }, {
    path: 'student/:id',
    component: StudentComponent,
    canActivate: [RoleGuard],
    data: {
      role: ['admin']
    },
    children: [
      {
        path: 'studienjahrgang/:studienjahrgangId',
        component: ModulteppichComponent
      }
    ]
  },
  {
    path: 'admin',
    component: V2adminComponent,
    canActivate: [RoleGuard],
    data: {
      role: 'admin'
    }
  },
  {
    path: 'unauthorized',
    component: UnauthorizedErrorPageComponent
  },
  {
    path: '**',
    redirectTo: '/'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes,
      {
        useHash: true,
        enableTracing: false
      })
  ],
  exports: [RouterModule],
  providers: [RedirectGuard, RoleGuard]
})
export class AppRoutingModule {
}
