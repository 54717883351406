import {Component, OnInit} from "@angular/core";
import {Studienjahrgang} from "../../../models/Studienjahrgang";
import {v2ApiService} from "../../services/v2api.service";
import {v2AdminService} from "../../services/v2admin.service";
import {SnackbarService} from "../../../services/snackbar.service";
import {ActivatedRoute, Router} from "@angular/router";
import {V2ModuleGroup} from "../../models/V2ModuleGroup";
import {V2Student} from "../../models/V2Student";

@Component({
  selector: 'app-v2admin',
  templateUrl: './v2admin.component.html',
  styleUrls: ['./v2admin.component.scss']
})

export class V2adminComponent implements OnInit {

  public loading = false;
  public hasError = false;
  public sortStudentsBy = { attribute: '', ascending: false };
  public errorMessage = '';

  public studierendeWithAnmeldungen: Array<V2Student> = [];
  public originalStudierendeWithAnmeldungen: Array<V2Student> = [];
  public currentSGmodulgruppen: Array<V2ModuleGroup> = [];
  public currentStudiengangId = 0;

  public filterChanged: any;
  public filtersDisabled: boolean = true;


  public studienjahrgangInfo: Array<Studienjahrgang> = [];

  constructor(
    public v2apiService: v2ApiService,
    public v2adminService: v2AdminService,
    public sncakbarService: SnackbarService,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.getQueryParams();
    this.applyFilters(this.filterChanged);
  }

  getQueryParams(): void {
    this.route.queryParams.subscribe(params => {
      const { vorname, nachname, studienjahrgangId } = params;
      if (!vorname && !nachname && !studienjahrgangId) {
        this.errorMessage = 'Wählen Sie bitte Filter, um Studierende zu suchen';
        this.hasError = true;
        this.originalStudierendeWithAnmeldungen = [];
        this.studierendeWithAnmeldungen = [];
      } else {
        if (studienjahrgangId) {
          this.fetchStudentsWithAnmeldungen(params);
          this.getStudienjahrgangModulgruppen(studienjahrgangId);
        } else if ( vorname || nachname) {
          this.fetchStudents(params);
          this.getStudienjahrgangModulgruppen(studienjahrgangId);
        }
      }
    });
  }

  public fetchStudents(params: any): void {
    const { vorname, nachname, studienjahrgangId } = params;
    this.loading = true;
    this.hasError = false;
    this.v2apiService.fetchStudents(vorname, nachname, studienjahrgangId).subscribe(studis => {
      this.studierendeWithAnmeldungen = studis;
    }, error => {
      this.showErrorMessage(error);
    }, () => {
      if (this.studierendeWithAnmeldungen.length > 0) {
        console.log(this.studierendeWithAnmeldungen);
        this.originalStudierendeWithAnmeldungen = [...this.studierendeWithAnmeldungen];
        this.fetchStudienjahrganginfo();
      } else {
        this.showErrorMessage();
      }
      this.loading = false;
    });
  }

  fetchStudienjahrganginfo(): void {
    const studienjahrgangAnmeldungIds: Array<string> = [];
    this.studierendeWithAnmeldungen
      .forEach(s => s.sjgAnmeldungen?.forEach(a =>
        studienjahrgangAnmeldungIds.push(a.sjgId!.toString())));
    if (studienjahrgangAnmeldungIds.length > 0) {
      this.v2apiService.fetchStudienJahrgaengeInfo(undefined, studienjahrgangAnmeldungIds)
        .subscribe(
        info => {
          this.studienjahrgangInfo = info;
        }, (error) => {
          this.sncakbarService.openHttpErrorResponse(error);
          this.hasError = true;
          this.loading = false;
        },
        () => {
          this.studierendeWithAnmeldungen = this.v2adminService.matchStudentsWithStudienjahrgangInfo(this.studierendeWithAnmeldungen, this.studienjahrgangInfo);
          this.hasError = false;
          this.loading = false;
        }
      );
    }
  }

  public fetchStudentsWithAnmeldungen(params: any): void {
    const { vorname, nachname, studienjahrgangId } = params;
    this.loading = true;
    this.hasError = false;
    this.v2apiService.fetchStudents(vorname, nachname, studienjahrgangId).subscribe(studis => {
      this.studierendeWithAnmeldungen = studis;
    }, (error) => {
      this.showErrorMessage();
    },
      () => {
      if (this.studierendeWithAnmeldungen.length > 0) {
        this.studierendeWithAnmeldungen = this.v2adminService.calculateStudentsAssessment(this.studierendeWithAnmeldungen, this.currentStudiengangId);
        this.studierendeWithAnmeldungen = this.v2adminService.calculateTotalFailedEctsStudents(this.studierendeWithAnmeldungen);

        this.originalStudierendeWithAnmeldungen = [...this.studierendeWithAnmeldungen];
        this.fetchStudienjahrganginfo();
      } else {
        this.showErrorMessage();
      }
      this.loading = false;
      });
  }

  public getStudienjahrgangModulgruppen(studienjahrgangId: string): void {
    this.v2apiService.fetchStudienJahrgaengeInfo(undefined, undefined, undefined, undefined, studienjahrgangId)
      .subscribe(
      data => {
        this.studienjahrgangInfo = data;
        this.currentStudiengangId = this.studienjahrgangInfo.find(s => s.studiengangId)?.studiengangId;
        if (this.currentStudiengangId) {
          this.v2apiService.fetchStudent().subscribe(
            data => {
              this.currentSGmodulgruppen = data.moduleGruppen;
            },
            error => {
              console.error('Error fetching modulgruppen', error);
            }
          );
        }
      },
      error => {
        console.error('Error fetching studienjahrgangInfo', error);
      }
    );
  }

  showErrorMessage(error?: any): void {
    if (error) {
      this.sncakbarService.openHttpErrorResponse(error);
      this.errorMessage = 'Oops, es ist ein Fehler aufgetreten';
    } else {
      this.errorMessage = 'Keine Studierende gefunden';
    }
    this.loading = false;
    this.hasError = true;
  }

  applyFilters(filters: any): void {
    if (!filters) {
      return;
    }

    let filteredStudents = [...this.originalStudierendeWithAnmeldungen];

    if (filters.showOnlyActive) {
      filteredStudents = this.v2adminService.calculateActiveStudents(filteredStudents);
    }

    if (filters.failedEcts > 0) {
      filteredStudents = this.v2adminService.filterCriticalStudents(filteredStudents, filters.failedEcts);
    }

    if (filters.passedAssessment && !filters.runningAssessment) {
      filteredStudents = this.v2adminService.calculateStudentsWithPassedAssessment(filteredStudents, this.currentStudiengangId);
    }

    if (filters.runningAssessment && !filters.passedAssessment) {
      filteredStudents = this.v2adminService.calculateStudentsWithRunningAssessment(filteredStudents, this.currentStudiengangId);
    }

    if (!filters.runningAssessment && !filters.passedAssessment) {
      filteredStudents = [];
    }

    if (filteredStudents.length > 0) {
      this.hasError = false;
      this.studierendeWithAnmeldungen = filteredStudents;
      this.filterChanged = filters;
    } else {
      this.showErrorMessage();
    }
  }

  detectFormSubmitted(params: any): void {
    this.router.navigate(['/admin'], { queryParams: params });
  }

  detectFiltersDisabled(value: boolean): void{
    this.filtersDisabled = value;
  }

}
