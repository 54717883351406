<div class="students" [hidden]="loading || hasError">
  <table class="table" *ngIf="studentsWithAnmeldungen.length > 0">
    <thead>
    <tr>
      <th></th>
      <th class="pointer name-column" (click)="toggleStudentAttributeSort('firstName')">
        <span>Vorname</span>
        <img class="sort-icon" *ngIf="themeService.isDark()" alt="sort by alphabet" src="../../../../assets/icons/sort-by-alpha-dark.svg">
        <img class="sort-icon" *ngIf="!themeService.isDark()" alt="sort by alphabet" src="../../../../assets/icons/sort-by-alpha-light.svg">
      </th>
      <th class="pointer name-column" (click)="toggleStudentAttributeSort('lastName')">
        <span>Nachname</span>
        <img class="sort-icon" *ngIf="themeService.isDark()" alt="sort by alphabet" src="../../../../assets/icons/sort-by-alpha-dark.svg">
        <img class="sort-icon" *ngIf="!themeService.isDark()" alt="sort by alphabet" src="../../../../assets/icons/sort-by-alpha-light.svg">
      </th>
      <th>Studiengang Bezeichnung</th>
      <th class="pointer ects-column" (click)="toggleStudentAttributeSort('totalFailedEcts')" *ngIf="!filtersDisabled">
        <div class="failed-ects-container">
          <div class="failed-circle"></div>
          <div>EСTS</div>
        </div>
      </th>
      <th  class="assessment-column" *ngIf="!filtersDisabled">Assessment</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let studiWithSJAInf of filteredStudentsWithStudienjahrgangInfo()" (click)="selectStudent(studiWithSJAInf)" class="search-student pointer">
      <td>
        <app-v2profile-image [student]="studiWithSJAInf"></app-v2profile-image>
      </td>
      <td class="student-first-name name-column">{{studiWithSJAInf.firstName}}</td>
      <td class="student-last-name name-column">{{studiWithSJAInf.lastName}}</td>
      <td>
        <div class="studienjahrgang-info-container">
          <div>{{studiWithSJAInf.studienjahrgangInfo?.bezeichnung.replace('Studienjahrgang', '')}}</div>
          <div *ngIf="!studiWithSJAInf.modulteppichIsPublic" title="Ist für Studierende nicht sichtbar" class="modulteppich-publicity-status">
            <img *ngIf="themeService.isDark()" alt="eye-closed" src="assets/icons/eye-closed-dark.svg">
            <img *ngIf="!themeService.isDark()" alt="eye-closed" src="assets/icons/eye-closed-light.svg">
          </div>
          <div *ngIf="studiWithSJAInf.modulteppichIsPublic" title="Ist für Studierende sichtbar" class="modulteppich-publicity-status">
            <img *ngIf="themeService.isDark()" alt="eye-open" src="assets/icons/eye-open-dark.svg">
            <img *ngIf="!themeService.isDark()" alt="eye-open" src="assets/icons/eye-open-light.svg">
          </div>
          <div *ngIf="studiWithSJAInf.modulteppichIsComplete" title="Ansicht existiert für diese Studiengangdefinition" class="modulteppich-publicity-status">
            <img *ngIf="themeService.isDark()" alt="checkmark" src="assets/icons/checkmark-dark.svg">
            <img *ngIf="!themeService.isDark()" alt="checkmark" src="assets/icons/checkmark-light.svg">
          </div>
          <div *ngIf="!studiWithSJAInf.modulteppichIsComplete" title="Ansicht existiert nicht für diese Studiengangdefinition" class="modulteppich-publicity-status">
            <img *ngIf="themeService.isDark()" alt="not-complete" src="assets/icons/not-complete-dark.svg">
            <img *ngIf="!themeService.isDark()" alt="not-complete" src="assets/icons/not-complete-light.svg">
          </div>
        </div>
        <div class="studiengang-anmeldung-status">{{parseStudiengangAnmeldungStatus(studiWithSJAInf)}}</div>
      </td>
      <td class="ects-column" *ngIf="!filtersDisabled">
        <div class="failed-color">{{studiWithSJAInf.totalFailedEcts}}</div>
      </td>
      <td class="assessment-column" *ngIf="!filtersDisabled">
        <div *ngIf="studiWithSJAInf.assessmentEcts < 27" class="laufend">Laufend</div>
        <div *ngIf="studiWithSJAInf.assessmentEcts >= 27" class="bestanden">Bestanden</div>
      </td>
    </tr>
    </tbody>
  </table>
  <div class="total-info">
    <div *ngIf="studentsWithAnmeldungen.length >= 100">
      Es werden nur die ersten 100 Studierende angezeigt
    </div>
    <div>Total: {{studentsWithAnmeldungen.length }}</div>
  </div>
</div>
<div *ngIf="hasError" class="students no-student-found">
  <span>{{ errorMessage }}</span>
</div>
<app-loader [isInAdmin]="true" class="students" *ngIf="loading"></app-loader>
