export interface V2SjgAnmeldung {
  sjgId: number;
  statusId: number;
  statusName: string;
  sjgAbbr: string;
  anmeldungsDatum?: string;
}

export const ignorableSJStatuses = [
  'Abgebrochen',
  'Abgebrochen',
  'Abgemeldet',
  'Abgemeldet',
  'Exmatrikuliert',
  'Exmatrikuliert'
];

export const ignorableSJAStatuses = [
  'j.Inaktiv',
  'j.Historisiert',
  'j.In Planung'
];

