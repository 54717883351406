import {Injectable} from "@angular/core";
import {V2SjgAnmeldung} from "../models/V2SjgAnmeldung";
import {IStudienjahrgang} from "../../models/Studienjahrgang";
import {Modulteppich} from "../../models/Modulteppich";
import {Studiengaenge} from "../../models/Studiengaenge";

@Injectable({
  providedIn: 'root'
})

export class V2parseService {

  constructor() {
  }

  public static readonly AVAILABLE_STUDIENGAENGE: Array<Modulteppich> = Studiengaenge.availableModulteppiche;

  public static parseStudyMode(sjg: IStudienjahrgang): IStudienjahrgang {
    sjg.bezeichnung = sjg.bezeichnung?.replace(' bb', ' Berufsbegleitend');
    sjg.bezeichnung = sjg.bezeichnung?.replace(' a', ' Vollzeit');
    return sjg;
  }
}
