import {Component, OnInit} from '@angular/core';
import {ParseService} from '../services/parse.service';
import {AuthService} from '../services/auth.service';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {ApiService} from '../services/api.service';
import {SnackbarService} from '../services/snackbar.service';
import {View} from "../views/views";
import {V2Student} from "../v2/models/V2Student";
import {v2ApiService} from "../v2/services/v2api.service";
import {v2ModulService} from "../v2/services/v2modul.service";

@Component({
  selector: 'app-student',
  templateUrl: './student.component.html',
  styleUrls: ['./student.component.scss']
})
export class StudentComponent implements OnInit {

  constructor(
    public parseService: ParseService,
    private authService: AuthService,
    public snackbarService: SnackbarService,
    private route: ActivatedRoute,
    public apiService: ApiService,
    private v2ApiService: v2ApiService,
    private v2ModulService: v2ModulService
  ) {
  }

  student: V2Student;

  ngOnInit(): void {
    if (this.authService.getRole() === 'student') {
      this.parseService.initData();
    } else {
      this.route.paramMap.subscribe((params: ParamMap) => {
        this.route.firstChild?.paramMap.subscribe(childParam => {
          this.apiService.fetchStudienJahrgaengeInfo(
            undefined,
            undefined,
            undefined,
            undefined,
            childParam.get('studienjahrgangId')!
          ).subscribe(sji => {
            this.parseService.initData(params.get('id')!, sji[0]);
          }, (error) => {
            this.snackbarService.openHttpErrorResponse(error);
          });
        });
      });
    }
    //v2
   this.fetchStudentInfo();
  }

  fetchStudentInfo() {
    this.v2ApiService.fetchStudent().subscribe(data => {
      this.student = data;
    }, error => {
      console.error('Error fetching student info', error);
    },
      () => {
        this.v2ModulService.setStudentModules(this.student.modules);
      });
  }

  public readonly View = View;
}
