import {V2Student} from "../../models/V2Student";
import {V2SjgAnmeldung} from "../../models/V2SjgAnmeldung";

export function getCurrentSJGA(studentWithInfo: V2Student | undefined): V2SjgAnmeldung {
  // Find the V2SjgAnmeldung that matches the Studienjahrgang ID
  const sjgAnmeldung = studentWithInfo?.sjgAnmeldungen?.find(
    (anmeldung) => anmeldung.sjgId === studentWithInfo?.studienjahrgangInfo?.studienjahrgangId
  );

  return sjgAnmeldung;
}
