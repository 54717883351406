import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from "@angular/core";
import {Studienjahrgang} from "../../../../models/Studienjahrgang";
import {FormControl, FormGroup, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {v2ApiService} from "../../../services/v2api.service";
import {SnackbarService} from "../../../../services/snackbar.service";
import {ActivatedRoute} from "@angular/router";
import {ParseService} from "../../../../services/parse.service";
import {ignorableSJAStatuses} from "../../../models/V2SjgAnmeldung";

@Component({
  selector: 'app-v2sidenav-admin',
  templateUrl: './v2sidenav-admin.component.html',
  styleUrl: './v2sidenav-admin.component.scss'
})
export class V2sidenavAdminComponent implements OnInit, OnChanges {


  parseStudyMode =  ParseService.parseStudyMode;

  public allStudienjahrgangInfos: Array<Studienjahrgang> = [];

  dropdownForm = this.fb.group({
    studienjahrgang: ['0'] //default value is 0
  });

  filtersForm = this.fb.group({
    failedEcts: ['0'], //default value is 0
    showOnlyActive: [false],
    runningAssessment: [true],
    passedAssessment: [true]
  });

  studentForm = new UntypedFormGroup({
    nachname: new UntypedFormControl(''),
    vorname: new UntypedFormControl('')
  });

  @Output() filterChanged = new EventEmitter<any>();
  @Output() formSubmitted = new EventEmitter<any>();

  @Input() currentStudentCount: number = 0;

  @Output() filtersDisabledOutput = new EventEmitter<boolean>();
  public filtersDisabled: boolean = true;

  constructor(
    private v2apiService: v2ApiService,
    private snackbarService: SnackbarService,
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder
  ) {
  }

  ngOnInit(): void {
    this.fetchDropdownInfo();
    this.fillStudentFormWithQueryParams();
    this.disableFilters();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.currentStudentCount == 0 || this.dropdownForm.get('studienjahrgang')?.value == 0) {
      this.disableFilters();
    }
    else {
      this.enableFilters();
    }
  }

  disableFilters(): void {
    this.filtersForm.reset({
      showOnlyActive: false,
      failedEcts: 0,
      runningAssessment: true,
      passedAssessment: true
    });
    this.filtersDisabledOutput.emit(true)
    this.filtersDisabled = true;
    this.filtersForm.disable();
  }

  private enableFilters() {
    this.filtersDisabledOutput.emit(false);
    this.filtersDisabled = false;
    this.filtersForm.enable();
  }

  /*
  * Fetch Dropdown infos
  * */
  private fetchDropdownInfo(): void {
    this.v2apiService.fetchStudienJahrgaengeInfo(undefined, undefined, undefined, true).subscribe(sji => {
      this.allStudienjahrgangInfos = this.normalizeStudienjahrgaenge(sji);
    }, (error) => {
      this.snackbarService.openHttpErrorResponse(error);
    });
  }

  private normalizeStudienjahrgaenge(studienjahrgaenge: Array<Studienjahrgang>): Array<Studienjahrgang> {
    return studienjahrgaenge
      .filter(s => s.bezeichnung) // remove null values
      .filter(s => !ignorableSJAStatuses.includes(s.statusName!)) // only show active
      .filter(s => !s.nummer?.substring(0, 5).toLowerCase().includes('m')) //remove masters programme
      .map(s => {
        s.bezeichnung = s.bezeichnung?.trim()?.replace('Studienjahrgang', '').trim();
        s.bezeichnung = s.bezeichnung?.trim().replace('Studiengang', '').trim();
        return s;
      }) // remove Studienjahrgang from bezeichnung
      .sort((a, b) => a.bezeichnung! > b.bezeichnung! ? 1 : -1);
  }

  fillStudentFormWithQueryParams(): void {
    this.route.queryParams.subscribe(params => {
      this.studentForm.get('vorname')?.setValue(params.vorname);
      this.studentForm.get('nachname')?.setValue(params.nachname);
      if (params.studienjahrgangId) {
        this.dropdownForm.get('studienjahrgang')?.setValue(params.studienjahrgangId);
      } else {
        this.dropdownForm.get('studienjahrgang')?.setValue(0);
      }
    });
  }

  clearFirstNameInput(): void {
    this.studentForm.get('vorname')?.setValue('');
  }

  clearLastNameInput(): void {
    this.studentForm.get('nachname')?.setValue('');
  }

  public detectFormChange(val: any): void {
    this.dropdownForm.get('studienjahrgang')?.setValue(val.target.value, {
      onlySelf: true
    });
  }

  getStudentForm(): FormGroup<any> {
    return this.studentForm;
  }

  getFiltersForm(): FormGroup<{ failedEcts: FormControl<number | null>; }> {
    return this.filtersForm;
  }

  /*
 * Sets the query params according to the fields filled in the students form
 * */
  fillQueryParamsWithStudentForm(): void {
    const params = {};
    if (this.studentForm.get('vorname')?.value) {
      Object.assign(params, {vorname: this.studentForm.get('vorname')?.value.trim()});
    }
    if (this.studentForm.get('nachname')?.value) {
      Object.assign(params, {nachname: this.studentForm.get('nachname')?.value.trim()});
    }
    if (this.dropdownForm.get('studienjahrgang')?.value != 0) {
      Object.assign(params, {studienjahrgangId: this.dropdownForm.get('studienjahrgang')?.value})
    }
    this.formSubmitted.emit(params);
  }

  applyFilters(): void {
    const filters = {
      showOnlyActive: this.filtersForm.get('showOnlyActive')?.value,
      failedEcts: this.filtersForm.get('failedEcts')?.value,
      runningAssessment: this.filtersForm.get('runningAssessment')?.value,
      passedAssessment: this.filtersForm.get('passedAssessment')?.value
    };
    this.filterChanged.emit(filters);
  }

  updateNegativeECTSSliderValue(value: number): void {
    if (value >= 0) {
      this.filtersForm.get('failedEcts')?.setValue(value);
    }
    this.applyFilters();
  }

  onActiveStudentsChange(): void {
    this.applyFilters();
  }

  onRunningAssessmentChange(): void {
    this.applyFilters();
  }

  onPassedAssessmentChange(): void {
    this.applyFilters();
  }

  setStudentForm(value: FormGroup<any>) {
    this.studentForm = value;
  }

  getDropdownForm(): FormGroup<any> {
    return this.dropdownForm;
  }

  setDropdownForm(value: FormGroup<any>) {
    this.dropdownForm = value;
  }
}
